import React, { useState } from "react";
import { Link as RouterLink, NavLink } from "react-router-dom";
import "./Navbar.css";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => setMenuOpen(false);

  const scrollToSection = (sectionId) => {
    try {
      const targetSection = document.getElementById(sectionId);

      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
        closeMenu();
      } else {
        console.error(`Section with ID "${sectionId}" not found.`);
      }
    } catch (error) {
      console.error(`Error while scrolling to section: ${error.message}`);
    }
  };

  const preventDefault = (event) => {
    const targetPath = event.currentTarget.getAttribute("to");

    // Check if the event was triggered by a button click
    if (targetPath && targetPath.startsWith("/") && !event.currentTarget.contains(event.nativeEvent.target)) {
      event.preventDefault();
      closeMenu();
    }
  };

  return (
    <nav>
      <div className="navback">
        <RouterLink to="/" className="title" onClick={closeMenu} >
          <img src="./resources/title.svg"/>
        </RouterLink>
      </div>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <NavLink to="/" onClick={closeMenu}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/speakers" onClick={closeMenu}>
            Speakers
          </NavLink>
        </li>
        <li>
          <NavLink to="/sponsors" onClick={closeMenu}>
            Sponsors
          </NavLink>
        </li>
        <li>
          <NavLink to="/team" onClick={closeMenu}>Team</NavLink>
        </li>
        <li>
          <NavLink to="/contact" onClick={closeMenu}>Contact Us</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
