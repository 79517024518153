import React from "react";
import "./Team_card.css";

export const Team_card = (props) => {
  
  return (
    <div className="Team_card">
        <div className="team_top">
          <img className="team_bg" src={props.img}/>
            <img src="./resources/team_card.svg" className="team_top1"/>
            <img src="./resources/def.svg" className="team_top2"/>
            
        </div>
    </div>
  );
};
export default Team_card;
