import React from "react";
import "./Contact.css";

export const Contact = () => {
  
  return (
    <div className="Contact">
        <img src="./resources/Contact.svg"/>
        <div className="contact_panel">
            <div className="contact_item">
                <img src="./resources/location.svg" className="contact_logo"/>
                <p className="contact_text"> MICA Ahmedabad, Telav - Ghuma Rd, Shela, Gujarat 380058</p>
            </div>
            <div className="contact_item">
                <img src="./resources/mail.svg" className="contact_logo"/>
                <p className="contact_text">Tedxmica@micamail.in</p>
            </div>
            <div className="contact_item">
                <img src="./resources/call.svg" className="contact_logo"/>
                <p className="contact_text">Madhur Mahajan (+91 9953013979)<br/>
                Tithi Choudhary (+91 7506349251)</p>
            </div>
        </div>
    </div>
  );
};
export default Contact;
