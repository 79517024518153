import React from "react";
import "./Sponsor_card.css";

export const Sponsor_card = (props) => {
  const style={
    fontSize: "1.2rem",
  }
  const myStyles = {
    backgroundColor: props.bg && '#0d0d0d',
  };

  const words = props.info.name.split('-');


  const firstWordStyle = words.length > 1 ? { color: 'red' } : {};

  return (
    <div className="Sponsor_card">
      <div className="Sponsors_card_img Sponsors_card_div" style={myStyles}>
        <img src={props.info.img} className="Sponsors_card_img" alt="Sponsor" />
      </div>
      <p className="Sponsors_card_title">
        <span style={firstWordStyle}>{words[0]}</span> <br/> <span style={style}>{words.slice(1).join(' ')}</span>
      </p>
    </div>
  );
};

export default Sponsor_card;
