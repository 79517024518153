import React from "react";
import "./Footer.css";
import { Link as RouterLink } from "react-router-dom";

export const Footer = () => {
  
  return (
    <div className="Footer">
        <div className="Footer_left">
            <img src="./resources/title2.svg" className="footer_left_img" alt="Title 1" />
            <img src="./resources/def.svg" className="footer_left_img" alt="Title 2" />
        </div>
        <div className="Footer_right">
            <RouterLink className="youtube" to="https://youtube.com/@TEDx" target="_blank" rel="noopener noreferrer">
                <img src="./footer/youtube.svg" className="footer_right_img" alt="YouTube" />
            </RouterLink>
            <RouterLink to="https://www.instagram.com/tedxmica" target="_blank" rel="noopener noreferrer">
                <img src="./footer/insta.svg" className="footer_right_img" alt="Instagram" />
            </RouterLink>
            <RouterLink to="https://www.facebook.com/tedxmica" target="_blank" rel="noopener noreferrer">
                <img src="./footer/fb.svg" className="footer_right_img" alt="Facebook" />
            </RouterLink>
            <RouterLink to="https://www.linkedin.com/company/teamtedxmica/" target="_blank" rel="noopener noreferrer">
                <img src="./footer/linkedin.svg" className="footer_right_img" alt="LinkedIn" />
            </RouterLink>
            <RouterLink to="https://x.com/TEDxMICA" target="_blank" rel="noopener noreferrer">
                <img src="./footer/twitter.svg" className="footer_right_img twitter_logo" alt="Twitter" />
            </RouterLink>
        </div>
    </div>
  );
};

export default Footer;
