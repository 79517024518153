import React from "react";
import "./Team.css";
import Team_card from "./Team_card.js";

export const Team = () => {
  return (
    <div className="Team">
        <img src="./resources/Team.svg" className="team_title"/>
        <div className="team_cards">
            <div className="team_panel">
                <Team_card img="./team/team1.png"/>
                <Team_card img="./team/team2.png"/>
            </div>
        </div>
    </div>
  );
};
export default Team;
