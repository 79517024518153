import React from "react";
import "./Sponsors.css";
import Sponsor_card from "./Sponsor_card";
import { isVisible } from "@testing-library/user-event/dist/utils";

export const Sponsors = () => {
  const data=[{img:"./sponsors/card1.png",name:"Ahmedabad Live	- Social Media Partner"},{img:"./sponsors/card2.png",name:"Brillar - Official Salon Partner"},{img:"./sponsors/card3.png",name:"Amrutam - Haircare Partner"},
              {img:"./sponsors/card4.png",name:"Nabhi Sutra - Wellness Partner"},{img:"./sponsors/card5.png",name:"Rewynd Snacks - Lifestyle Snacking Partner"},{img:"./sponsors/card6.png",name:"Plum BodyLovin' - Bath and Body Partner"},
              {img:"./sponsors/card7.png",name:"Aksa - Jewelry Partner"},{img:"./sponsors/card8.png",name:"Baar Baar Dekho - Stationary Partner"},{img:"./sponsors/card9.png",name:"Scrapshala - Gifting Partner"},
              {img:"./sponsors/card10.png",name:"Fling - Official Eyewear Partner"},{img:"./sponsors/card11.png",name:"Shimmmi - Official Beverage Partner"},{img:"./sponsors/card12.png",name:"Unibic - Snack Partner"},
              {img:"./sponsors/card13.png",name:"Vinzberry - Official Personal Care Partner"},{img:"./sponsors/card14.png",name:"Wingreens - Gourmet Food and Beverage Partner"},{img:"./sponsors/card15.png",name:"KabhiB - Official Food Partner"},
              {img:"./sponsors/card16.png",name:"GFB Fruitchill - Refreshments’ Partner"},{img:"./sponsors/card17.png",name:"Moody (RAS Luxury Oils) - Beauty Partner"},{img:"./sponsors/card18.png",name:"Kettle Studio	- Snack Partner"}]
  return (
    <div className="Sponsors">
        <img src="./sponsors/Sponsors.svg" className="Sponsors_title"/>
        <div className="Sponsors_cards">
            <div className="Sponsors_cards_panel">
                <Sponsor_card info={data[0]} bg={true}/>
                <Sponsor_card info={data[1]} bg={false}/>
                <Sponsor_card info={data[2]} bg={false}/>
            </div>
            <div className="Sponsors_cards_panel">
                <Sponsor_card info={data[3]} bg={true}/>
                <Sponsor_card info={data[4]} bg={false}/>
                <Sponsor_card info={data[5]} bg={false}/>
            </div>
            <div className="Sponsors_cards_panel">
                <Sponsor_card info={data[6]} bg={false}/>
                <Sponsor_card info={data[7]} bg={true}/>
                <Sponsor_card info={data[8]} bg={false}/>
            </div>
            <div className="Sponsors_cards_panel">
                <Sponsor_card info={data[9]} bg={true}/>
                <Sponsor_card info={data[10]} bg={true}/>
                <Sponsor_card info={data[11]} bg={true}/>
            </div>
            <div className="Sponsors_cards_panel">
                <Sponsor_card info={data[12]} bg={true}/>
                <Sponsor_card info={data[13]} bg={false}/>
                <Sponsor_card info={data[14]} bg={false}/>
            </div>
            <div className="Sponsors_cards_panel">
                <Sponsor_card info={data[15]} bg={false}/>
                <Sponsor_card info={data[16]} bg={false}/>
                <Sponsor_card info={data[17]} />
            </div>
            
        
    </div>
    </div>
  );
};
export default Sponsors;
