import React from "react";
import "./Home.css";


export const Home = () => {
  
  return (
    <div className="Home">
      <div className="bg_video">
        <div className="home_video"></div>
        <div className="video_elements">
            <div className="video_heading">
                <img src="./resources/title2.svg" className="main_title"/>
                <img src="./resources/def.svg" className="main_title_desc"/>
            </div>
            <h3 className="video_text">
            <span>Unscrpited</span> <span className="redefine">Redefine</span><div className="red_element text_poss">Possibilities</div>
            </h3>
        </div>
      </div>
      <div className="home_panel1">
            <h3 className="panel1_title"><img src="./resources/home_mica.svg" className="home_mica"/> </h3>
            <img src="./resources/home_bg2.png" className="img_panel1"/>
            <div className="panel_text">
                <p>
                Nestled amidst the vibrant tapestry of Ahmedabad, MICA stands tall as a pioneer in the realm of strategic marketing and communication. Established in 1991, long before the current boom in B-schools, MICA carved its own niche, refusing to be confined by the traditional management education mould. Here, we break free from the shackles of convention, igniting minds to become transformative storytellers and architects of meaningful brand experiences.<br/><br/>
                Here, marketing isn't just a numbers game; it's a symphony of emotion, logic, and intuition. We dissect cultural nuances, decode consumer desires, and weave narratives that resonate across oceans and demographics.<br/><br/>
                Life at MICA is a kaleidoscope of experiences, where late-night brainstorming sessions fuelled by chai collide with vibrant cultural fests and rigorous academic pursuits. Our campus, a haven of creativity nestled amidst sprawling green spaces, pulsates with the energy of ambition, collaboration, and laughter. It's where friendships are forged in the crucible of shared challenges, and mentors become lifelong guides, nurturing not just intellectual prowess, but also empathy, resilience, and a sense of purpose.</p>
            </div>
        </div>
        <div className="home_panel2">
            <img src="./resources/title3.svg" className="panel2_title"/>
            <img src="./resources/strike.png" className="panel2_img"/>
            <div className="panel_text">
                <p>
                In the spirit of ideas worth spreading, TEDx is a program of local, self-organized events that bring people together to share a TED-like experience. At a TEDx event, TEDTalks video and live speakers combine to spark deep discussion and connection in a small group. These local, self-organized events are branded TEDx, where x = independently organized TED event. The TED Conference provides general guidance for the TEDx program, but individual TEDx events are self-organized (subject to certain rules and regulations).<br/><br/>

                <span className="red_element">Strikethrough</span> is about celebrating fearless experimentation. It’s an ode to the self-assurance of canceling errors and starting over - no matter how many trials it takes. While the world scrambles for shelter in the face of uncertainty, we bring speakers who ride the waves of unpredictability - ready to face any curveball that comes their way. Living with trials and errors is a scary place to be in, and the only way to soar to success is by sporting an undying spirit. <br/><br/>

                Life is, after all, a sum total of the good, the bad, and the ugly. Having the courage to try again and not be scared in the face of possible failure makes all the difference. TEDxMICA 2022-23 hails all who make these brave choices and wear them like a badge of honor. </p>
            </div>
        </div>
    </div>
  );
};
export default Home;
