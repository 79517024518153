import React from "react";
import "./Card.css";

export const Card2 = (props) => {
  
  return (
    <div className="Card1">
        <div className="card_title">{props.data.name} <span className="bar">|</span><div className="red_element card_title_red">{props.data.prof}</div>
        </div>
        <div className="card_bottom">
            <div className="card_text">
            <p>{props.data.desc}</p>
            </div>
            <div className="card_img card_img2">
                <img className="card_photo " src={props.data.img}/>
            </div>
        </div>
    </div>
  );
};
export default Card2;
