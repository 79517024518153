import React from "react";
import "./Speakers.css";
import Card1 from "./Card1";
import Card2 from "./Card2";

export const Speakers = (props) => {
  const project_data=[{name:"Dinesh S. Thakur",prof:"Public Health Activist",desc:"Dinesh Thakur is a public health activist. His work focuses on access to high-quality generic medicines globally. His efforts in the prosecution of India’s largest pharmaceutical company in 2013 in a US court won him the Joe A. Callaway Award for Civic Courage in 2014.",img:"./speakers/card1.png"},
                      {name:"Harsh Kedia",prof:"CEO, A Diabetic Chef, Author",desc:"Harsh Kedia, featured in Forbes 30 under 30 list, is a chef-turned-entrepreneur. Being diagnosed with type 1.5 diabetes at the age of 14, he later founded - ‘A Diabetic Chef’. He is also an author, motivational speaker and has founded and co-founded multiple startups",img:"./speakers/card2.png"},
                      {name:"Mini Mathur",prof:"Actor, Model, TV Host, VJ",desc:"Mini Mathur is India’s leading television presenter and reality TV host. She has hosted six seasons of the extremely popular ‘Indian Idol’ on Sony Entertainment Television and has been one of MTV India’s most popular VJs. A reluctant actor, she recently essayed the title role in “ Mind The Malhotras” on Amazon Prime. She is currently the producer and presenter of her travel series “ MiniMe” the first season of which premiered on TLC discovery.",img:"./speakers/card3.png"},
                      {name:"Monika Halan",prof:"Writer, Author, Speaker, Chairperson",desc:"Monika Halan is a trusted personal finance writer, speaker, and author who helps families get their money decisions right. She is the Chairperson of SEBI Advisory Committee for Investor Protection and Education Fund and also the founder of Dhan Chakra Financial Education.",img:"./speakers/card4.png"},
                      {name:"Sai Ganesh",prof:"Quizmaster, Founder",desc:"Sai Ganesh is a renowned marketer turned full-time quiz master. After successful stints in branding and marketing over 14 years at companies like Dunzo and AB InBev, Sai is now building India's first Knowledgetainment Content Studio with his brand India Wants To Know (IWTK).",img:"./speakers/card5.png"},
                      {name:"Shalini Prakash",prof:"Venture Capitalist, Founder, and Investor",desc:"Shalini Prakash is a venture capitalist, investor and author of the popular book ‘Clueless at 30’. She is also the founder of Purple Matter - a technology-agnostic early-stage investing platform. She was also recognized as LinkedIn top voice in 2017.",img:"./speakers/card6.png"},
                      {name:"Shatbhi Basu",prof:"India's First Female Bartender, Author, Entrepreneur",desc:"Shatbhi Basu is India's first female bartender. She is a published author, a teacher and owns STIR - a bartending academy. She is also a renowned beverage consultant and brand ambassador who put her considerable skills and knowledge into revolutionizing restaurant menus and championing global beverage education.",img:"./speakers/card7.png"}]
  return (
    <div className="Speakers">
        <div className="speakers_panel">
            <img src="./resources/Speakers.svg"/>
            <img src="./speakers/hero.png" className="speakers_panel_img"/>
        </div>
        <img src="./speakers/2023_Speakers.svg" className="speakers_2023"/>
        <div className="speakers_cards">
        <Card1 data={project_data[0]}/>
        <Card2 data={project_data[1]}/>
        <Card1 data={project_data[2]}/>
        <Card2 data={project_data[3]}/>
        <Card1 data={project_data[4]}/>
        <Card2 data={project_data[5]}/>
        <Card1 data={project_data[6]}/>
        </div>
    </div>
  );
};
export default Speakers;
